<template>
    <div>
        <div class="form-row w-1/2">
            <div class="form-col">
                <label>Images</label>
                <file-manager
                    :main-directory="'cms'"
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :max-nb-of-files="15"
                    :max-upload-size="20"
                    :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                    :noCropper="true"
                    @uploaded-to-s3="createWebsiteImage"
                    v-model="images"
                >
                    <template v-slot:hint="{ canAddFiles, maxNbOfFiles, maxUploadSize }">
                        <span class="form-hint" v-if="canAddFiles">
                            Select at least {{ nbImages }} images (up to {{ maxNbOfFiles }}).
                            The max upload file size is {{ maxUploadSize }}MB.
                        </span>
                    </template>
                </file-manager>
                <span v-if="hasError('images')" class="form-error">{{ showError('images') }}</span>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, FileManager },
        emits: ['save-page-widget'],
        data: () => {
            return {
                images: [],
                nbImages: 10,
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    let images=[]
                    for( let i = 0; i < this.images.length; i++){
                        images[i] = (this.getThumbnailUrlByFileId(this.images[i]));
                    }
                    this.$emit('save-page-widget', { images: JSON.stringify(images) });
                }
            },
        },
        validations: {
            'images' : 'required | min:nbImages',
        },
        created() {
            this.initValidator();
            this.validator.setCustomErrorMessages({
                'images.min' : 'You must select at least ' + this.nbImages + ' images.',
            })

            if (this.values.length){
                let images=[]
                for( let i = 0; i < JSON.parse(this.getValue('images')).length; i++){
                    images[i] = (this.getFileIdByThumbnailUrl(JSON.parse(this.getValue('images'))[i]));
                }            
                this.images = JSON.stringify(images) ? images : []; 
            }
        }
    }
</script>
